export const skillset = [
  { skill: "HTML", icon: "fab fa-html5", id: "1" },
  { skill: "CSS", icon: "fab fa-css3-alt", id: "2" },
  { skill: "JavaScript", icon: "fab fa-js-square", id: "3" },
  { skill: "React", icon: "fab fa-react", id: "4" },
  { skill: "Vue", icon: "fab fa-vuejs", id: "5" },
  { skill: "Node", icon: "fab fa-node-js", id: "6" },
  { skill: "SQL", icon: "fas fa-database", id: "7" },
  { skill: "Python", icon: "fab fa-python", id: "8" },
];
const project1 = require("./project1.jpg");
const project2 = require("./project2.png");
export const projects = [
  {
    title: "Don't Let It Die API",
    description:
      "Users can create an account and start adding the info of their green little friends, as many as they want! And keep track of how often they need to water each one of them.",
    link: "https://github.com/water-my-plants-tt-webpt-172/back-end",
    image: project1,
    id: "1",
  },
    {
    title: "Water Your Plants",
    description:
      "Vue 3 CRUD UI where users can keep track of the plants they have.",
    link: "https://github.com/water-my-plants-tt-webpt-172/back-end",
    image: project2,
    id: "2",
  },
];
